<!-- 视频详情页 -->
<template>
  <div id="doctorDetail">
    <Header></Header>
    <div class="new-breadcrumb">
      <ScienceBreadcrumb :text="text"></ScienceBreadcrumb>
    </div>
    <div class="science-box">
      <div class="video-box">
        <div id="img-content" class="rich_media_wrp">
          <div class="video-title">
            <h1 class="rich_media_title" id="activity-name">
              {{ videoInfo.title }}
            </h1>
            <!-- <el-button class="share" circle size="small" @click="shareLinks">
              <i class="iconfont icon-fenxiang"></i>
            </el-button> -->

            <el-popover
              placement="bottom"
              title="分享视频"
              width="200"
              trigger="click"
              class="monitor-pop"
              @hide="handleShareClose"
            >
              <div class="share-pop">
                <div class="code-box" style="margin-bottom: 0.75rem">
                  <div class="qrcode" ref="qrCodeDiv"></div>
                </div>
                <div
                  class="links-box"
                  style="padding: 0.5rem; border: 1px solid #eee"
                >
                  <p style="line-height: 1.5">{{ links }}</p>
                </div>
                <div
                  class="copy-box"
                  style="
                    display: flex;
                    justify-content: center;
                    padding-top: 0.75rem;
                  "
                >
                  <el-button
                    size="small"
                    @click="copeLinks"
                    >复制链接</el-button
                  >
                </div>
              </div>
              <el-button
                class="share-btn"
                slot="reference"
                circle
                @click="shareLinks"
              >
                <i class="iconfont icon-fenxiang"></i>
              </el-button>
            </el-popover>
          </div>

          <div class="video-summary">
            {{ videoInfo.summary }}
          </div>

          <div class="video-content">
            <!-- <d-player ref="player" :options="options"></d-player> -->
            <!-- <video controls style="width:100%">
            <source :src="options.video.url" type="video/mp4" />
            <source src="myVideo.webm" type="video/webm" />
            <p>
              你的浏览器不支持 HTML5 视频。这里有一个<a
                :href="options.video.url"
                :download="options.video.url"
              >视频</a>链接。
            </p>
          </video> -->
            <video
              width="100%"
              style="object-fit: fill"
              v-if="videoInfo.titleImage"
              :src="videoInfo.content"
              :poster="videoInfo.titleImage"
              controls
            >
              <source
                codecs="theora, vorbis"
                media="video/ogg"
                :src="videoInfo.content"
              />
              <source media="video/x-ms-wmv" :src="videoInfo.content" />
            </video>

            <video
              width="100%"
              style="object-fit: fill"
              v-else
              :src="videoInfo.content"
              controls
            >
              <source
                codecs="theora, vorbis"
                media="video/ogg"
                :src="videoInfo.content"
              />
              <source media="video/x-ms-wmv" :src="videoInfo.content" />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>

    <!-- <el-dialog
      title="分享切片"
      :visible.sync="shareFlag"
      width="30%"
      :before-close="handleShareClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="share-dialog"
    >
      <div class="code-box">
        <div class="qrcode" ref="qrCodeDiv"></div>
      </div>
      <div class="links-box">
        <p>{{ links }}</p>
      </div>
      <div class="copy-box">
        <el-button size="small" @click="copeLinks">复制链接</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleShareClose">取 消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import ScienceBreadcrumb from "../componets/ScienceBreadcrumb.vue";
import api from "../../api/api";
import QRCode from "qrcodejs2";
export default {
  name: "doctorDetail",
  props: {},
  components: {
    Header,
    Footer,
    ScienceBreadcrumb,
  },
  data() {
    return {
      text: "吉博士视频详情",
      videoInfo: {},
      id: null,
      shareFlag: false,
      links: "", // 分享链接
      options: {
        // sources: [
        //   {
        //     type: "video/mp4",
        //     src: "",
        //   },
        // ],
        // poster: "",
        video: {
          url: "",
          pic: "",
        },
      },
    };
  },
  created() {
    let id = this.$route.params.id;
    this.id = id;

    this.getDetailsVideo(id);
  },
  mounted() {},
  watch: {},
  methods: {
    getDetailsVideo(id) {
      api
        .getVideoById(id)
        .then((res) => {
          console.log(res.data);
          this.videoInfo = res.data.models[0];

          // this.$nextTick(() => {
          // this.$refs.player.src = this.videoInfo.content;

          // this.options["sources"][0]["src"] = this.videoInfo.content;
          // this.options.poster = this.videoInfo.titleImage;

          this.options.video.url = this.videoInfo.content;
          this.options.video.pic = this.videoInfo.titleImage;

          // console.log(this.options);
          // });
        })
        .catch((error) => {});
    },
    shareLinks() {
      this.shareFlag = true;
      // this.links = `http://localhost:8082/#/doctorDetail/${this.id}`;
      this.links = window.location.href;
      this.bindQRCode();
    },
    // 生成二维码函数
    bindQRCode() {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeDiv, {
          text: this.links, //url地址  文本等需要转换为二维码的内容
          width: 156,
          height: 156,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
        });
      });
    },
    handleShareClose() {
      //清除二维码
      this.$refs.qrCodeDiv.innerHTML = "";
    },
    // 复制链接
    copeLinks() {
      // navigator.clipboard.writeText(this.links).then(() => {
      //   this.$message.success("复制成功");
      // });
      
      var input = document.createElement("input"); // 创建input对象
      input.value = this.links; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
  },
};
</script>
    
<style lang="scss">
#doctorDetail {
  .new-breadcrumb {
    padding-top: 5rem;
  }
  .science-box {
    position: relative;
    padding: 2rem 0;
    box-sizing: border-box;
    background-image: url("../../assets/image/science/video-bg.png");
    background-size: cover;
    background-position: center center;
    .video-box {
      width: 65%;
      padding: 2rem;
      margin: auto;
      background-color: #fff;
      border-radius: 1rem;
      box-shadow: 0px 5px 20px 0px rgba(183, 183, 195, 0.07);
    }
    .video-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.2rem;
      .rich_media_title {
        font-size: 1.375rem;
        // line-height: 1.4;
        // text-align: center;
      }
      .share-btn {
        padding: 0.6rem;
        i {
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
      .monitor-pop {
        .share-pop {
          width: 100%;
          .code-box {
            width: 100%;
            height: 10.875rem;
            margin-bottom: 10px;
            .qrcode {
              display: flex;
              justify-content: center;
            }
          }
          .links-box {
            padding: 0.5rem;
            border: 1px solid #eee;
            p {
              line-height: 1.5;
            }
          }
          .copy-box {
            padding-top: 12px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .video-summary {
      margin-bottom: 1.5rem;
      font-family: "OPlusSans3-Regular";
    }

    .video-content {
      // white-space: pre-wrap;
      font-family: "Microsoft Yahei";
      font-size: 1.125rem;
      color: #333;
      p {
        line-height: 2;
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
  .video-content {
    img {
      max-width: 100% !important;
      display: block !important;
      margin: 20px auto !important;
    }
    // p {
    //   font-family: "OPlusSans3-Regular";
    // }
    .ql-align-left {
      text-align: left;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
  }
}
</style>